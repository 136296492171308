import React, { useEffect, useState, useContext } from 'react';
import { fabric } from 'fabric';
import { ColorContext } from '../context/ColorContext';
import { useParams } from "react-router-dom";

const ProductImageComponentTwo = ({ canvas, setImage, setSelectedPart, activePart, setActivePart, Canvasid,pid,setWorkingArea }) => {
  const { selectedColor } = useContext(ColorContext);
  const [imageSrc, setImageSrc] = useState(null);
  const [backgroundLayer, setBackgroundLayer] = useState(null);
  const [availableParts, setAvailableParts] = useState([]);
  const offsetValue = 0.01;
  const [products, setProducts] = useState([]);
  const [tshirtData, setTshirtData] = useState(null); // State for tshirtDesignData

  const productCanvasID = Canvasid;

  // Fetch product data
  useEffect(() => {
    fetch('https://backend.inkox.com/json/productData.json')
      .then((response) => response.json())
      .then((data) => {
        console.log('Fetched product data:', data.products); // Preview JSON data in the console
              let filteredProducts = null;

      data.categories.forEach(category => {
        console.log('Checking category:', category.mainCategory);
        category.subCategories.forEach(subCategory => {
          console.log('Checking subCategory:', subCategory.subCategoryName);
          const foundProduct = subCategory.products.find(product => {
            console.log('Checking product:', product.productName, 'with id:', product.id);
            return product.id === parseInt(pid);
          });
          if (foundProduct) {
            console.log("FoundProduct:", foundProduct);
            filteredProducts = foundProduct;
          }
        });
      });
        // const filteredProducts = data.products.filter(product => product.id === pid);
        console.log('Filtered product data:', filteredProducts);
        setProducts(filteredProducts); // Set the filtered products
      })
      .catch((error) => console.error('Error fetching product data:', error));
  }, [productCanvasID,pid]);

  console.log(products);


  useEffect(() => {
    fetch('https://backend.inkox.com/api/tool-apparels')
      .then((response) => response.json())
      .then((data) => {
        const filteredProduct = data.find(product => product.id === parseInt(Canvasid));
        if (filteredProduct) {
          setTshirtData(filteredProduct);
        } else {
          console.log('No product found with the given Canvasid');
        }
      })
      .catch((error) => console.error('Error fetching tshirt design data:', error));
  }, [Canvasid]);


  useEffect(() => {
    if (tshirtData && tshirtData.parts) {
      
      const parts = JSON.parse(tshirtData.parts);
      const part = parts[1]; 
  
      let description = null;

      if (part && part.description) {
          try {
              // Parse the description string into a JSON object if it exists
              description = JSON.parse(part.description);
          } catch (error) {
              console.error('Invalid JSON format for description:', error);
          }
      } else {
          console.log('Description is null or undefined.');
      }
  
      const defaultPart = parts.find(part => part.name === activePart) || part;
      console.log("Default part:", defaultPart);
  
      if (description && description.DesignAreaComponent2) {
        const designArea = description.DesignAreaComponent2;
        const workingArea = description.workingArea;
        const  productImage = description.ProductImageComponent2;
        console.log("DesignAreaComponent2:", designArea);
        console.log("DesignAreaComponent2:", workingArea);
        console.log("ProductImage :", productImage);
        setWorkingArea(workingArea);
        setSelectedPart(designArea);
  
        // Proceed with loading the image for ProductImageComponent1
        if (part.ProductImageComponent2 && part.ProductImageComponent2.image) {
          console.log(part.ProductImageComponent2.image);
          const imageUrl = `https://backend.inkox.com${part.ProductImageComponent2.image}`;
          setImageSrc(imageUrl); 
        } else {
          console.log('No image found in ProductImageComponent1');
        }
      } else {
        console.log('No DesignAreaComponent1 found in the description');
      }
    }
  }, [tshirtData]);
  
  
console.log("imgsrc :", imageSrc);

  // useEffect(() => {
  //   if (!tshirtData) return;

  //   // Filter the parts from tshirtData based on Canvasid
  //   const productData = tshirtData.productCanvasID === Canvasid ? tshirtData : null;

  //   if (productData && Array.isArray(productData.parts)) {
  //     if (productData.parts.length > 0) {
  //       setAvailableParts(productData.parts);
  //       const defaultPart = productData.parts.find(part => part.name === activePart) || productData.parts[0];
  //       setSelectedPart(defaultPart);
  //       setImageSrc(defaultPart.ProductImageComponentOne.image);
  //       console.log('Default part selected:', defaultPart);
  //     } else {
  //       console.log('No parts found for productCanvasID:', Canvasid);
  //     }
  //   } else {
  //     console.log('No matching product data found for Canvasid:', Canvasid);
  //   }
  // }, [activePart, setSelectedPart, Canvasid, tshirtData]);

  console.log(tshirtData);

  useEffect(() => {
    if (!canvas || !imageSrc) {
      console.log('Canvas not initialized or image not set');
      return;
    }

    const loadImage = (url) => {
      return new Promise((resolve, reject) => {
        fabric.Image.fromURL(url, (img) => {
          if (img) {
            resolve(img);
          } else {
            reject(new Error('Image failed to load'));
          }
        }, { crossOrigin: 'anonymous' });
      });
    };

    const setBackgroundImage = async () => {
      try {
        const img = await loadImage(imageSrc);
        console.log('Image loaded:', img);

        let bgLayer = backgroundLayer;
        if (!bgLayer) {
          bgLayer = new fabric.Rect({
            fill: selectedColor,
            selectable: false,
            evented: false,
            originX: 'center',
            originY: 'center',
            backgroundLayer: true,
            designElement: false,
          });

          canvas.add(bgLayer);
          setBackgroundLayer(bgLayer);
          console.log('Background layer created and added to canvas:', bgLayer);
        } else {
          bgLayer.set({ fill: selectedColor });
          console.log('Background layer color updated:', bgLayer.fill);
        }

        const adjustImageAndLayer = () => {
          const canvasAspect = canvas.width / canvas.height;
          const imgAspect = img.width / img.height;

          if (imgAspect > canvasAspect) {
            img.scaleToHeight(canvas.height);
          } else {
            img.scaleToWidth(canvas.width);
          }

          img.set({
            selectable: false,
            evented: false,
            originX: 'center',
            originY: 'center',
            top: canvas.height / 2,
            left: canvas.width / 2,
            designElement: false,
          });

          const offsetX = img.getScaledWidth() * offsetValue;
          const offsetY = img.getScaledHeight() * offsetValue;

          bgLayer.set({
            width: img.getScaledWidth() - 2 * offsetX,
            height: img.getScaledHeight() - 2 * offsetY,
            top: img.top,
            left: img.left,
            designElement: false,
          });

          console.log('Background layer adjusted with offset:');
          console.log('Position:', { top: bgLayer.top, left: bgLayer.left });
          console.log('Size:', { width: bgLayer.width, height: bgLayer.height });
          console.log('Fill:', bgLayer.fill);

          if (!canvas.getObjects().includes(img)) {
            canvas.add(img);
            console.log('Product image added to canvas');
          }
          canvas.sendToBack(img);
          canvas.sendToBack(bgLayer);
          canvas.renderAll();
        };

        // Clear previous product image only
        const productImages = canvas.getObjects().filter(obj => obj.productImage);
        productImages.forEach((obj) => canvas.remove(obj));

        img.set({ productImage: true });
        adjustImageAndLayer();
        setImage(img);

        const adjustImageAndLayerPosition = () => {
          if (!canvas || !img || !bgLayer) {
            console.log('Canvas, image, or background layer is not initialized');
            return;
          }
        
          const canvasWidth = canvas.width;
          const canvasHeight = canvas.height;
        
          // Define the minimum width the image can have
          const minImageWidth = 700; // Set this to your desired minimum width in pixels
        
          // Calculate the scale while respecting the minimum width constraint
          const scale = Math.min(
            Math.max(canvasWidth / img.width, minImageWidth / img.width), 
            canvasHeight / img.height
          );
        
          img.set({
            scaleX: scale,
            scaleY: scale,
            top: canvasHeight / 2,
            left: canvasWidth / 2,
            designElement: false,
          });
        
          const offsetX = img.getScaledWidth() * offsetValue;
          const offsetY = img.getScaledHeight() * offsetValue;
        
          bgLayer.set({
            width: img.getScaledWidth() - 2 * offsetX,
            height: img.getScaledHeight() - 2 * offsetY,
            top: img.top,
            left: img.left,
            designElement: false,
          });
        
          console.log('Adjusted layer and image positions');
          console.log('Layer position:', { top: bgLayer.top, left: bgLayer.left });
          console.log('Layer size:', { width: bgLayer.width, height: bgLayer.height });
          console.log('Layer fill:', bgLayer.fill);
        
          if (canvas.contextContainer) {
            canvas.renderAll();
          }
        };

        window.addEventListener('resize', adjustImageAndLayerPosition);

        adjustImageAndLayerPosition();

        return () => {
          window.removeEventListener('resize', adjustImageAndLayerPosition);
        };

      } catch (error) {
        console.error('Error setting background image:', error);
      }
    };

    setBackgroundImage();
  }, [canvas, setImage, imageSrc, selectedColor]);

  useEffect(() => {
    if (backgroundLayer) {
      backgroundLayer.set({ fill: selectedColor });
      if (canvas.contextContainer) {
        canvas.renderAll();
      }
    }
  }, [selectedColor, backgroundLayer, canvas]);

  const handlePartChange = (part) => {
    setActivePart(part.name);
    setSelectedPart(part);
    setImageSrc(part.ProductImageComponentOne.image);
    console.log('Part changed to:', part);
  };

  return (
    <div>
      <div id="canvas-container">
        {/* Canvas will be rendered here */}
      </div>
    </div>
  );
};

export default ProductImageComponentTwo;
