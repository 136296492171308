import React, { useEffect, useState } from "react";
import productFilterImage from "../assets/images/shirt3.png";
import tipOfTheDayImage from "../assets/images/tipoftheday-bnr.png";
import LayoutWrapper from "../layout/layoutWrapper/LayoutWrapper";
import Faqs from "../widgets/faqs/Faqs";
import ProductDetail from "../widgets/productDetail/ProductDetail";
import RelatedProduct from "../widgets/relatedProduct/RelatedProduct";
import Reviews from "../widgets/reviews/Reviews";
import TipOfTheDay from "../widgets/tipOfTheDay/TipOfTheDay";
import { useLocation } from "react-router-dom";

const SingleProductDetail = () => {
  const tipOfTheDay = {
    image: tipOfTheDayImage,
    title:
      "<span class='text-red'>Tip</span> of the <span class='text-skyblue'>Day</span>",
    description:
      "Page yield is how many pages your printer cartridge can produce before it needs replacement. To calculate the Paper cost, here's another math task:",
  };

  const [rproduct, setRProduct] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const location = useLocation();
  const pathSegments = location.pathname.split("/");

  const selectedProductId = rproduct.id;
  
  useEffect(() => {
    const fetchData = () => {
      fetch(`https://backend.inkox.com/api/product-subcategories-all/${decodeURIComponent(pathSegments[5])}`)
        .then((response) => response.json())
        .then((data) => {
          const productsArray = data.map((item) => item.products).flat();
          const relatedProducts = productsArray.filter((product) => product.id !== rproduct.id);
          setFilteredProducts(relatedProducts);
        })
        .catch((error) => console.error("Error fetching related products:", error));
    };

    // Debounce the API call to prevent rapid multiple requests
    const fetchTimeout = setTimeout(fetchData, 500); // Adjust delay as needed

    return () => clearTimeout(fetchTimeout);
  }, [selectedProductId, pathSegments]);

  useEffect(() => {
    const pathSegments = location.pathname.split("/"); // Split the URL by "/"
    
    // Extract specific parts based on the structure of the URL
    const category = pathSegments[2];
    const categoryId = decodeURIComponent(pathSegments[3]);        // "headwears"
    const subcategoryId = pathSegments[4];  // "53"
    const productName = decodeURIComponent(pathSegments[5]); // "sustainable beanie"
    const productId = pathSegments[6];      // "727"
    
    console.log("Category:", category);
    console.log("categoryId:", categoryId);
    console.log("Subcategory ID:", subcategoryId);
    console.log("Product Name:", productName);
    console.log("Product ID:", productId);
    
    // You can use these values as needed in your component
  }, [location]);

  
  const productsWithFilters = filteredProducts.slice(0, 8).map((product) => {
    const firstProductImage = product.vendor === "snscanada"
    ? `https://backend.inkox.com/${product.productImages?.split(',')[0].replace("Images", "storage")}` 
    : `https://backend.inkox.com${product.productImages?.split(',')[0]}` || productFilterImage;
    return {
    image: `${firstProductImage}`, 
    topSeller: false, 
    title: product.name,
    size: product.productSize ? JSON.parse(product.productSize).join(", ") : "",
    rating: "4", 
    reviews: "548",
    link: `/products/${pathSegments[2]}/${decodeURIComponent(pathSegments[3])}/subCategory/${decodeURIComponent(pathSegments[5])}/${product.name}/${product.id}`
    };
  });

  const reviews = [
    {
      user: "Jimmy D.",
      rating: 4,
      verified: true,
      time: "33 hours ago",
      review:
        "Would I recommend this company? Early signs is a resounding YES! <br /> I looked at this company in depth, after requesting a phone call to discuss my r..",
      replyes: [
        {
          user: "Printofly",
          department: "Sales Department",
          time: "33 hours ago",
          reply:
            "Would I recommend this company? Early signs is a resounding YES! <br /> I looked at this company in depth, after requesting a phone call to discuss my r..",
        },
      ],
    },
    {
      user: "Jimmy D.",
      rating: 4,
      verified: false,
      time: "33 hours ago",
      review:
        "Would I recommend this company? Early signs is a resounding YES! <br /> I looked at this company in depth, after requesting a phone call to discuss my r..",
      replyes: [],
    },
    {
      user: "Jimmy D.",
      rating: 4,
      verified: false,
      time: "33 hours ago",
      review:
        "Would I recommend this company? Early signs is a resounding YES! <br /> I looked at this company in depth, after requesting a phone call to discuss my r..",
      replyes: [],
    },
    {
      user: "Jimmy D.",
      rating: 4,
      verified: false,
      time: "33 hours ago",
      review:
        "Would I recommend this company? Early signs is a resounding YES! <br /> I looked at this company in depth, after requesting a phone call to discuss my r..",
      replyes: [],
    },
  ];

  return (
    <LayoutWrapper search={true}>
      <ProductDetail setRProduct={setRProduct} />
      <Reviews data={reviews} />
      <RelatedProduct data={productsWithFilters} /> {/* Display dynamically populated products */}
      <Faqs />
      <TipOfTheDay bgColor={"#fff"} data={tipOfTheDay} />
    </LayoutWrapper>
  );
};

export default SingleProductDetail;
