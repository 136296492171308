import { Collapse } from "antd";
import React, { useState } from "react";
import Description from "../typography/description/Description";
import Title from "../typography/title/Title";
import plusIcon from "../../assets/images/plus-icon.png";
import minusIcon from "../../assets/images/minus-icon.png";

const Accordion = ({ data, size }) => {
  const { Panel } = Collapse;
  const [collapse, setCollapse] = useState(1);

  return (
    <div className={`accordion ${size && size}`}>
      <Collapse
        expandIconPosition="end"
        activeKey={collapse}
        onChange={(e) => setCollapse(e)}
        accordion
        expandIcon={({ isActive }) =>
          isActive ? (
            <img src={minusIcon} alt="minus-icon" />
          ) : (
            <img src={plusIcon} alt="plus-icon" />
          )
        }
      >
        {data.map((item, index) => {
          return (
            <Panel
              header={
                <Title
                  size={size === "small" ? "22" : "30"}
                  tag={"h3"}
                  weight={700}
                >
                  {item.title}
                </Title>
              }
              key={index + 1}
            >
              <Description
                customClass={`${size === "small" ? "fs-18" : "fs-30"} fw-500`}
              >
                {item.description}
              </Description>
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default Accordion;
