import { MenuFoldOutlined } from "@ant-design/icons";
import { Col, Drawer, Menu, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import Cart from "../../assets/images/cart.png";
import login from "../../assets/images/login.png";
import callicon from "../../assets/images/call-13.png";

import Container from "../../components/container/Container";
import { removeFromLocalStorage, getFromLocalStorage } from "../../utils/helperFunctions";
import ChatTalk from "../../widgets/chatTalk/ChatTalk";
import './header.css';
import Cookies from 'js-cookie';
import bumberger from "../../assets/humbergertool.png";


const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState("/");
  const [userData, setUserData] = useState({
    email: getFromLocalStorage("Email"),
    id: getFromLocalStorage("Id"),
  });
  const userId = getFromLocalStorage("Id");
  const email = Cookies.get('Email_guest');
  const email1 = getFromLocalStorage("Email");

  // Manage cart length with state
  const [cartLength, setCartLength] = useState(parseInt(localStorage.getItem('cartLength') || 0));

  // Fetch cart data and update the cartLength state
  const fetchCartData = (emailToFetch) => {
    fetch(`https://backend.inkox.com/api/checkouts/email/${emailToFetch}`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          setCartLength(data.length); // Update cartLength state
          localStorage.setItem('cartLength', data.length); // Update localStorage
        } else if (data && typeof data === 'object' && Object.keys(data).length > 0) {
          setCartLength(1); // Handle the case where a single object is returned
          localStorage.setItem('cartLength', 1);
        } else {
          setCartLength(0); // Set to 0 if no items found
          localStorage.setItem('cartLength', 0);
        }
      })
      .catch((error) => {
        console.error('Error fetching cart data:', error);
        setCartLength(0); // Set to 0 on error
        localStorage.setItem('cartLength', 0);
      });
  };

  // Fetch cart data on component load based on email
  useEffect(() => {
    if (email) {
      fetchCartData(email);
    } else if (email1) {
      fetchCartData(email1);
    }
  }, [email, email1]);
  const items = [
    {
      label: "products",
      key: "products",
    },
    {
      label: "Template",
      key: "template",
      children: [
        {
          label: "School Template",
          key: "setting:1",
        },
        {
          label: "Birthday Template",
          key: "setting:2",
        },
        {
          label: "Events Template",
          key: "setting:3",
        },
        {
          label: "Adobe Template",
          key: "setting:4",
        },
      ],
    },
    {
      label: "Help Center",
      key: "help-center",
    },
    {
      label:  "Login", // Show email if logged in, else show "Login"
      key: userData ? "profile" : "login",
      children: userData?.email
        ? [
            { label: "Update Profile", key: "update-profile" },
            { label: "Update Password", key: "update-password" },
            { label: "Logout", key: "logout" },
          ]
        : [],
    },
    {
      label: "DESIGN NOW",
      key: "designer?id=24&pid=73&colorId=700007",
      className: "design-now-btn",
      url:'http://localhost:3000/designer?id=24&pid=73&colorId=700007',
    },
  ];

  useEffect(() => {
    if (userId && !userData.email) {
      fetch(`https://backend.inkox.com/api/user_login/${userId}`)
        .then((response) => response.json())
        .then((data) => {
          localStorage.setItem("Email", data.email);
          setUserData(data);
        })
        .catch((error) => console.error("Error fetching user data:", error));
    }
  }, [userId, userData.email]);

  useEffect(() => {
    if (location?.pathname) {
      let route = location?.pathname?.split("/")?.[1];
      setCurrent(route);
    }
  }, [location?.pathname]);

  const onClick = (e) => {
    if (e.key === "logout") {
      handleLogout();
    } else if (e.key === "login") {
      navigate("/login");
    } else {
      navigate(`/${e.key}`);
      setCurrent(e.key);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleSidebar = () => {
    setOpen(true);
  };

  const handleLogout = () => {
    removeFromLocalStorage("Id");
    removeFromLocalStorage("Email");
    removeFromLocalStorage("APP_TOKEN");
    localStorage.clear();
    setUserData(null);
    navigate("/login");
  };


    


  return (
    <header className="header">
      <div
        className="top-nav"
        style={{
          backgroundColor: "#f5f5f5",
          padding: "0px 30px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 1, textAlign: "center" }}>
          <h3 className="tagline">
            Custom T-shirts & Promotional Products, Fast & Free Shipping, and
            All-Inclusive Pricing
          </h3>
        </div>

        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <Link to={`/cart`}>
            <button  className="icon-headers icon-headersemail"
              style={{
                padding: "6px 0px",
                backgroundColor: "transparent",
                border: "none",
                color: "#1f1f2b",
                fontSize: "16px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={Cart}
                alt="cart"
                style={{ width: "auto", height: "20px", marginRight: "8px" }}
              />
              Cart ({cartLength})
            </button>
          </Link>
          <div id="google_translate_element"></div>

          {/* If no userData, show the login button */}
          {!userData?.email ? (
            <Link to="/login">
              <button className="icon-headers icon-headersemail "
                style={{
                  padding: "6px 0px",
                  backgroundColor: "transparent",
                                    border: "none",
                  color: "#1f1f2b",
                  fontSize: "16px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={login}
                  alt="login"
                  style={{ width: "auto", height: "20px", marginRight: "8px" }}
                />
                Login
              </button>
            </Link>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* Display user's email */}
              <span style={{ marginRight: "15px", fontSize: "12px" }}>
                {userData.email}
              </span>
              <button
                onClick={handleLogout}
                style={{
                  padding: "6px 0px",
                  backgroundColor: "",
                  border: "none",
                  color: "#1f1f2b",
                  fontSize: "12px",
                  cursor: "pointer",
                }}
              >
                Logout
              </button>
            </div>
          )}

<Link to="/login">
              <button className="icon-headers formobileonly"
                style={{
                  padding: "6px 0px",
                  backgroundColor: "transparent",
                                    border: "none",
                  color: "#1f1f2b",
                  fontSize: "16px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={callicon}
                  alt="call"
                  style={{ width: "auto", height: "20px", marginRight: "8px" }}
                />
                Help
              </button>
            </Link>
        </div>
      </div>

      <Container className="container-header-main">
        <Row gutter={[40, 20]} justify={"space-between"} className="row-wesite-header" align={"middle"}  style={{ flexFlow: 'row',  padding:'12px 0px'}} // Add flex-flow property here
        >
     <Col md={2} className="mobile-menu" style={{ textAlign: "right" }}>
    <img 
        src={bumberger} 
        alt="Menu" 
        className="sideBarIcon" 
        style={{ cursor: "pointer" }} 
        onClick={handleSidebar} 
    />
</Col>

          <Col xs={10} sm={12} md={12} lg={12} xl={4} className="logo-colomn-mobile">
            <Link to={"/"} className="brand-logo">
              <img src={logo} alt="logo" />
            </Link>
          </Col>


          <Col xs={12} sm={10} md={10} lg={10} xl={0} className="">
          <div style={{ display: "flex", justifyContent:"right", alignItems: "center", gap: "5px" }}>
          <Link to={`/cart`}>
            <button  className="icon-headerstwo"
              style={{
                padding: "6px 0px",
                backgroundColor: "",
                border: "none",
                color: "#1f1f2b",
                fontSize: "12px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={Cart}
                alt="cart"
                style={{ width: "auto", height: "20px", marginRight: "8px" }}
              />
              Cart ({cartLength})
            </button>
          </Link>
          <div id="google_translate_element"></div>

          {/* If no userData, show the login button */}
          {!userData?.email ? (
            <Link to="/login">
              <button className="icon-headerstwo"
                style={{
                  padding: "6px 0px",
                  backgroundColor: "",
                  border: "none",
                  color: "#1f1f2b",
                  fontSize: "12px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={login}
                  alt="login"
                  style={{ width: "auto", height: "20px", marginRight: "8px" }}
                />
                Login
              </button>
            </Link>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* Display user's email */}
              <span style={{ marginRight: "15px", fontSize: "12px" }}>
                {userData.email}
              </span>
              <button
                onClick={handleLogout}
                style={{
                  padding: "6px 0px",
                  backgroundColor: "",
                  border: "none",
                  color: "#1f1f2b",
                  fontSize: "12px",
                  cursor: "pointer",
                }}
              >
                Logout
              </button>
            </div>
          )}
        </div>
          </Col>
          <Col md={14} className="desktop-menu">
            <Menu
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={items}
            />
          </Col>

          <Col md={6} className="desktop-menu">
            <ChatTalk />
          </Col>
          
        </Row>
      </Container>
      <Drawer
        placement="left"
        onClose={onClose}
        open={open}
        className="header-sidebar"
      >
        <Menu
          onClick={onClick}
          selectedKeys={[current]}
          mode="inline"
          items={items}
        />
      </Drawer>
    </header>
  );
};

export default Header;
