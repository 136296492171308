import {AutoComplete, Col, Collapse, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import arrowDownIcon from "../../assets/images/arrow-down.png";
import sortIcon from "../../assets/images/sort-icon.png";
import SimpleCard from "../../components/simpleCard/SimpleCard";
import Title from "../../components/typography/title/Title";
import { CloseOutlined, FilterOutlined } from "@ant-design/icons";
import { useAppSelector } from "../../redux/hooks";
import "antd/dist/reset.css";

// Loader component
const Loader = () => (
  <div className="spinner"></div>
);

const ProductsWithCategories = () => {
  const { Search } = Input;
  const { Panel } = Collapse;
  const [collapse, setCollapse] = useState(1);
  const [sidebar, showSidebar] = useState(false);
  const [category, setCategory] = useState([]);
  const [subcategory, setSubCategory] = useState([]);
  const [initialCategories, setInitialCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true); // Loading state for categories
  const [loadingSubCategories, setLoadingSubCategories] = useState(true); // Loading state for subcategories
  const [options, setOptions] = useState([]); // New state for search suggestions
  const [sidebarCategories, setSidebarCategories] = useState([]); // State for sidebar categories


  const navigate = useNavigate(); // useNavigate hook from react-router-dom
  const onSearch = (value) => {
    const filteredOptions = initialCategories
      .filter((cat) => cat.name.toLowerCase().includes(value.toLowerCase()))
      .map((cat) => ({ value: cat.name }));
    setOptions(filteredOptions);
  
    if (value === "") {
      setCategory(initialCategories); // Reset main content categories when input is cleared
    }
  };
  
  const onSelect = (value) => {
    const selectedCategory = initialCategories.filter(
      (cat) => cat.name.toLowerCase() === value.toLowerCase()
    );
    setCategory(selectedCategory); // Only update main content
  };
  
  
 
  
  useEffect(() => {
    setCategory(initialCategories);
  }, [initialCategories]);

  const onChange = (value) => {
    if (value) {
      const filteredCategories = initialCategories.filter(
        (cat) => cat.id === value
      );
      setCategory(filteredCategories);
    } else {
      setCategory(initialCategories);
    }
  };

  const fetchCategories = () => {
    setLoadingCategories(true);
    fetch("https://backend.inkox.com/api/product-categories")
      .then((response) => response.json())
      .then((data) => {
        setCategory(data); // For main content
        setSidebarCategories(data); // For sidebar
        setInitialCategories(data);
        setLoadingCategories(false);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
        setLoadingCategories(false);
      });
  };
  

  const fetchSubCategories = () => {
    setLoadingSubCategories(true);
    fetch("https://backend.inkox.com/api/product-sub-categories")
      .then((response) => response.json())
      .then((data) => {
        setSubCategory(data);
        setLoadingSubCategories(false); // Stop loader when data is fetched
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
        setLoadingSubCategories(false);
      });
  };

  useEffect(() => {
    fetchCategories();
    fetchSubCategories();
  }, []);

  const handleSidebar = () => {
    const body = document.querySelector("body");
    if (!sidebar) {
      showSidebar(true);
      body.classList.add("filter-sidebar-active");
    } else {
      showSidebar(false);
      body.classList.remove("filter-sidebar-active");
    }
  };

  // Handle subcategory click and navigate to its page
  const handleSubCategoryClick = (categoryId,categoryName,subCategoryId) => {
    navigate(`/products/${categoryName}/${categoryId}/subcategory/${subCategoryId}`);
  };

  // Log categories and subcategories for debugging purposes
  useEffect(() => {
    console.log("Categories:", category);
    console.log("Subcategories:", subcategory);
  }, [category, subcategory]);

  return (
    <div className="productsWithCategories">
      {/* Show loader when fetching categories or subcategories */}
      {(loadingCategories || loadingSubCategories) && <Loader />}

      {!loadingCategories && !loadingSubCategories && (
        <>
          <Row className="page-header">
            <Col className="title-section" md={24}>
              <Title size={"41"} weight={700} tag={"h1"}>
                Custom Apparel
              </Title>
              <Title customClass={"sub-title"} size={"21"} weight={500} tag={"h5"}>
                Personalize apparel with free & fast shipping
              </Title>
            </Col>
          </Row>
          <Row align={"middle"} className="header-row">
            <Col md={5} className="d-desktop">
              <Title size={"26"} weight={400} tag={"h3"}>
                Available options
              </Title>
            </Col>
            <Col xs={24} md={6} className="d-mobile">
              <button onClick={handleSidebar} className="filter-btn">
                <Title size={"26"} weight={400} tag={"h3"}>
                  Filters
                </Title>
                <FilterOutlined />
              </button>
            </Col>
            <Col md={19}>
              <Row justify={"space-between"} align={"middle"}>
                <Col md={17}>
                  <div className="search-container">
                    <Title size={"20"} weight={400} tag={"h3"}>
                      {`Showing <span class="title fw-700"> ${category.length} </span> of <span class="title fw-700">${category.length}</span>`}
                    </Title>
                    <AutoComplete style={{border:'0px'}}
  className="custom-search"
  placeholder="Search categories"
  options={options} // Displayed suggestions
  onSearch={onSearch} // For generating suggestions
  onSelect={onSelect} // Handle selection of suggestion
  allowClear
/>

                  </div>
                </Col>
                <Col xs={24} md={6}>
                  <div className="sorting-container">
                    <div className="image-container">
                      <img src={sortIcon} alt="sort-icon" className="sort-icon" />
                      <Title size={"20"} weight={400} tag={"h3"}>
                        Sort:
                      </Title>
                    </div>
                    <Select
                      showSearch
                      placeholder="Select a category"
                      onChange={onChange}
                      allowClear
                      options={initialCategories.map((cat) => ({
                        value: cat.id,
                        label: cat.name,
                      }))}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="filter-content">
            <Col xs={24} md={5} className={`filter-sidebar ${sidebar ? "show" : ""}`}>
              <div className="d-mobile sidebar-close-btn">
                <CloseOutlined onClick={handleSidebar} />
              </div>
              <Collapse
  expandIconPosition="end"
  className="filters"
  activeKey={collapse}
  onChange={(e) => setCollapse(e)}
  accordion
>
  {sidebarCategories.length > 0 &&
    sidebarCategories.map((category, index) => {
      const matchingSubCategories = subcategory.filter(
        (subCategory) =>
          parseInt(subCategory.category_id) === parseInt(category.id)
      );

      return (
        <Panel header={category.name} key={index + 1} className="categorypage-main-cls">
          <ul className="category-listing categorypage-main">
            {matchingSubCategories.length > 0 ? (
              matchingSubCategories.map((subCategory, i) => (
                <li key={i} onClick={() => handleSubCategoryClick(category.id, category.name, subCategory.id)}>
                  <Title size={"17"} weight={400} tag={"h5"}>
                    {subCategory.name}
                  </Title>
                </li>
              ))
            ) : (
              <li>No subcategories available</li>
            )}
          </ul>
        </Panel>
      );
    })}
</Collapse>

            </Col>
            <Col md={19}>
              <Row className="productListing" gutter={[40, 0]}>
                {category?.length > 0 &&
                  category.map((category, index) => {
                    const payload = {
                      title: category.name,
                      image: `https://backend.inkox.com/storage/${category.icon_path}`,
                      link: `${category.name}/${category.id}`,
                    };
                    return (
                      <Col xs={24} md={8} key={index}>
                        <SimpleCard cardType={"categoryCard"} data={payload} />
                      </Col>
                    );
                  })}
              </Row>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default ProductsWithCategories;
