import React, { useEffect, useState, useRef } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"; // Importing left and right icons

const ProductSlider = ({ data, selectedImage, onImageClick, selectedColor }) => {
  const [active, setActive] = useState(0);
  const sliderRef = useRef(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollPosition = useRef(0);

  useEffect(() => {
    // Set active index based on selectedImage whenever selectedImage changes
    const index = data.findIndex((img) => img === selectedImage);
    if (index !== -1) {
      setActive(index);
    }
  }, [selectedImage, data]);

  // Scroll functions for arrows
  const scrollLeftByButton = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRightByButton = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  // Handle drag events for slider
  const onMouseDown = (e) => {
    isDragging.current = true;
    startX.current = e.pageX - sliderRef.current.offsetLeft;
    scrollPosition.current = sliderRef.current.scrollLeft;
  };

  const onMouseMove = (e) => {
    if (!isDragging.current) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX.current) * 2; // Adjust scroll speed as needed
    sliderRef.current.scrollLeft = scrollPosition.current - walk;
  };

  const onMouseUpOrLeave = () => {
    isDragging.current = false;
  };

  return (
    <div className="productSlider" style={{ borderRadius: "8px", position: "relative" }}>
      {/* Large preview image */}
      <img
        src={selectedImage}
        alt="product-large-img"
        className="main-img"
        style={{
          width: "100%",
        }}
      />

      {/* Left Icon for scrolling */}

      <div className="icon-product-image">
      <FaChevronLeft
        className="slider-icon left"
        onClick={scrollLeftByButton}
        style={{
          position: "absolute",
          height:"100%",
          left:"0",
          background: "#f0f2f5",
          fill:"#1f1f2b",
          padding:"0px 5px",
          fontSize:"16px",
          zIndex: "9",
          

    
        
    
       
          cursor: "pointer",
        }}
      />

      {/* Slider Container with Thumbnails */}
      <div
        className="slider-container"
        ref={sliderRef}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUpOrLeave}
        onMouseLeave={onMouseUpOrLeave}
        style={{ cursor: isDragging.current ? "grabbing" : "grab" }}
      >
        {data.map((item, index) => (
          <button
            key={index}
            onClick={() => {
              onImageClick(item);
              setActive(index);
            }}
            className={`slider-button ${active === index ? "active" : ""}`}
          >
            <img
              src={item}
              style={{ backgroundColor: selectedColor || "#fff" }}
              alt={`product-${index}`}
              className={`slider-img ${active === index ? "active" : ""}`}
            />
          </button>
        ))}
      </div>

      {/* Right Icon for scrolling */}
      <FaChevronRight
        className="slider-icon right"
        onClick={scrollRightByButton}
        style={{
          position: "absolute",
          right: "0",
          height:"100%",
          background: "#f0f2f5",
          fill:"#1f1f2b",
          padding:"0px 5px",
          zIndex: "9",

    
       
          fontSize: "16px",
          cursor: "pointer",
        }}
      />
      </div>
    </div>
  );
};

export default ProductSlider;
