import React from "react";
import Container from "../../components/container/Container";
import { Col, Row } from "antd";
import SimpleCard from "../../components/simpleCard/SimpleCard";
import Title from "../../components/typography/title/Title";

const RelatedProduct = ({ data }) => {
  return (
    <div className="relatedProduct">
      <Container>
        <Row className="page-header">
          <Col className="title-section" md={24}>
            <Title size={"41"} weight={700} tag={"h1"}>
              Related Products
            </Title>
            <Title
              customClass={"sub-title"}
              size={"21"}
              weight={500}
              tag={"h5"}
            >
              Design your t-shirt online with fast and free shipping
            </Title>
          </Col>
        </Row>
        <Row gutter={[30, 40]}>
          {data.map((item, index) => {
            return (
              <Col xs={24} md={6} key={index}>
                <SimpleCard data={item} cardType={"filterCard"} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default RelatedProduct;
