import React from "react";
import Accordion from "../../components/accordion/Accordion";
import Title from "../../components/typography/title/Title";

const Faqs = () => {
  const accordions = [
    {
      title: "How do I design a custom t-shirt?",
      description:
        "At Inkox, designing a custom t-shirt is easy! Simply choose your t-shirt type, upload your design or use our design tools, and select your customization options like print locations. Our team can guide you through every step if needed. For quick price estimates, use our Quote Calculator on the product page.",
    },
    {
      title: "How much do custom shirts cost?",
      description:
        "Inkox custom shirt prices vary based on the t-shirt style, quantity, and design locations. We offer volume pricing, meaning the more you order, the lower the per-shirt cost. Get an accurate quote using the Quote Calculator on any product page or reach out for a personalized estimate.",
    },
    {
      title: "Is there a minimum quantity for custom shirts?",
      description:
        "Yes, at Inkox, we have a minimum order quantity to ensure the best pricing and production quality. Minimums vary by product type, so please check the product page or contact us to learn more. We’re happy to discuss your needs and help with smaller or larger orders.",
    },
    {
      title: "How quickly can I get personalized t-shirts delivered?",
      description:
        "Inkox offers flexible delivery options to meet your schedule. Our standard production time is 14 days, with a rush option available in 1 week and a super rush option in just 4 days. Delivery times may vary depending on your location, and since we're based in Canada and the US, we can offer fast shipping across North America. Contact us for exact timelines on your order.",
    },
    
    {
      title: "What is your return policy?",
      description:
        "Inkox prioritizes customer satisfaction! Due to the custom nature of our products, we review returns on a case-by-case basis. If there are any issues with your order, reach out within 30 days of receiving your items, and we’ll work with you to find a solution. Your satisfaction is our priority!",
    },
    
  ];
  return (
    <div className="faqs">
      <Title size={"40"} customClass={"heading"} tag={"h2"} weight={700}>
        Custom T-Shirt FAQs
      </Title>
      <Accordion data={accordions} />
    </div>
  );
};

export default Faqs;
