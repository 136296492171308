import React, { useEffect, useState } from "react";
import "./ProductColor.css";
import { Button, Switch } from "antd";
import { fabric } from "fabric";
import { SketchPicker } from "react-color";
import ImagePaletteComponent from "./ImagePaletteComponent";
import ImagePaletteBox from "./ImagePaletteBox";
import axios from "axios";
import bringtofront from '../assets/bringtofront.png';

import mirrorvertical from '../assets/vertical.png';

import mirrorhorizontal from '../assets/horizontal.png';
import lock from '../assets/lock.png';
import unlock from '../assets/unlock.png';
import objectcenter from '../assets/objectcenter.png';
import close from "../assets/close.png"
import colorclip from "../assets/colormobicon.svg"
import transformclip from "../assets/transformclip.svg"
import rotationmobtext from "../assets/rotationmobtext.svg"
import backgroundColor from "../assets/background-color.svg"



export default function DetailImageBox({
  imgwidth,
  imgheight,
  initialPaletteColors,
  canvas,
  isPng,
  setIsPng,
  addToHistory,
  saveCanvasDataToLocalStorage,
  activeTab,
  calculatePrice,
  closePropertiesSidebar,
  showColorPalette,
  setShowColorPalette,
  
}) {
  const [isLocked, setIsLocked] = useState(false);
  const [rotation, setRotation] = useState(0); // New state for rotation

  const [removeBackground, setRemoveBackground] = useState(false);
  const [color, setColor] = useState("#000000");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [colorPicker, setColorPicker] = useState(false);
  const [originalImageUrl, setOriginalImageUrl] = useState(null); // Store original image URL
  const [dominantColorCode, setDominantColorCode] = useState(null);
  const [singleColorMode, setSingleColorMode] = useState(false);
  const [makeColorMode, setMakeColorMode] = useState(false);
  const [singleColor, setSingleColor] = useState({ r: 0, g: 0, b: 0 });
  const [makeColor, setMakeColor] = useState({ r: 0, g: 0, b: 0 });
  const [paletteColors, setPaletteColors] = useState(initialPaletteColors);
  const [showImagePalette, setShowImagePalette] = useState(false); // New state for palette colors
  const activeObject = canvas.getActiveObject();
    const isMobileScreen = window.innerWidth <= 900; // To detect mobile screen
  const [activeTabIndex, setActiveTabIndex] = useState(null); // Manage active tab for mobile screens
  const [isSelected, setIsSelected] = useState(false);
  const [isFlippedHorizontal, setIsFlippedHorizontal] = useState(false); // State for flip horizontal
  const [isFlippedVertical, setIsFlippedVertical] = useState(false); // St
  const [isCentered, setIsCentered] = useState(false);

  console.log(singleColor);

  useEffect(() => {
    const activeObject = canvas.getActiveObject();
    console.log('active single color', activeObject);
  
      setRemoveBackground(activeObject.bgRemove);
      setMakeColorMode(activeObject.makeColorMode);
      setSingleColorMode(activeObject.singleColorMode);
      setMakeColor(activeObject.makeColor);
      setSingleColor(activeObject.singleColor);
      setIsFlippedHorizontal(activeObject.flipX || false);
      setIsFlippedVertical(activeObject.flipY || false);
      setIsCentered(activeObject.isCentered || false); 
      setIsSelected(activeObject.isSelected || false); 
      setRotation(activeObject.angle || 0);
    

  
    console.log('active single color', activeObject.singleColorMode);
  }, [canvas, canvas.getActiveObject()]);

  const handleCenterObject = () => {
    const canvas = window._canvas;
    const selectedObject = canvas ? canvas.getActiveObject() : null;
    if (selectedObject) {
      selectedObject.center();
      selectedObject.setCoords();
      canvas.renderAll();
  
      // Save the center state in the object
      selectedObject.isCentered = true; // Manually store the centered state
    }
  
    // Toggle the selected state for center button
    setIsCentered(!isCentered);
  };
  
  const handleBringToFront = () => {
    const canvas = window._canvas;
    const selectedObject = canvas ? canvas.getActiveObject() : null;
    if (selectedObject) {
      selectedObject.bringToFront();
      canvas.renderAll();
  
      // Save the bring-to-front state in the object
      selectedObject.isSelected = true; // Manually store the bring-to-front state
    }
  
    setIsSelected(!isSelected); // Toggle the state
  };
  

 const handleFlipHorizontal = () => {
    const canvas = window._canvas;
    const selectedObject = canvas ? canvas.getActiveObject() : null;
    if (selectedObject) {
      selectedObject.toggle('flipX');
      canvas.renderAll();
    }

    // Toggle the selected state for flip horizontal
    setIsFlippedHorizontal(!isFlippedHorizontal); 
  };

  const handleFlipVertical = () => {
    const canvas = window._canvas;
    const selectedObject = canvas ? canvas.getActiveObject() : null;
    if (selectedObject) {
      selectedObject.toggle('flipY');
      canvas.renderAll();
    }

    // Toggle the selected state for flip vertical
    setIsFlippedVertical(!isFlippedVertical); 
  };


  const handleRotationChange = (value) => {
    const canvas = window._canvas;
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      // Get the center position of the object before rotation
      const center = activeObject.getCenterPoint();
  
      // Apply the rotation and set the origin to center
      activeObject.set({
 
        centeredRotation: true,
        angle: value, // Set the rotation angle
      });
  
      // Restore the center position of the object
      activeObject.setPositionByOrigin(center, "center", "center");
  
      // Update the object's coordinates and re-render the canvas
      activeObject.setCoords();
      setRotation(value); // Update the state for the rotation value
      canvas.renderAll();
    }
  };
  
  

  const handleToggleLock = () => {
    const canvas = window._canvas;
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      activeObject.set({
        lockMovementX: !isLocked,
        lockMovementY: !isLocked,
        lockScalingX: !isLocked,
        lockScalingY: !isLocked,
        lockRotation: !isLocked,
        lockUniScaling: !isLocked,
      });
      setIsLocked(!isLocked);
      canvas.renderAll();
    }
  };

 
  

  const handleColorChange = (color) => {
    const canvas = window._canvas;
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === "image") {
      const blendColorFilter = new fabric.Image.filters.BlendColor({
        color: color.hex,
        mode: "tint",
        alpha: 0.5,
      });
      activeObject.filters = [blendColorFilter];
      activeObject.applyFilters();
      canvas.renderAll();
    }
  };

  function loadImageFromUrl(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = function () {
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
  
        var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        var data = imageData.data;
        var colorMap = {};
        var maxCount = 0;
        var dominantColor = [0, 0, 0];
  
        for (var i = 0; i < data.length; i += 4) {
          var color = [data[i], data[i + 1], data[i + 2]];
          var key = color.join(",");
          colorMap[key] = (colorMap[key] || 0) + 1;
          if (colorMap[key] > maxCount) {
            maxCount = colorMap[key];
            dominantColor = color;
          }
        }
        var rgb = "rgb(" + dominantColor.join(",") + ")";
        resolve(rgb);
      };
      img.onerror = function (error) {
        reject(error);
      };
      img.src = url;
    });
  }
  
  const removeBgColor = async (value) => {
    try {
      const activeObject = canvas.getActiveObject();
      if (activeObject && activeObject.type === "image") {
        if (value) {
          const imgSrc = activeObject.getSrc();
          const dominantColor = await loadImageFromUrl(imgSrc);
          setDominantColorCode(dominantColor);
  
          const removeColorFilter = new fabric.Image.filters.RemoveColor({
            distance: 0.2,
            color: dominantColor,
            alpha: 1,
          });
          activeObject.filters.push(removeColorFilter);
          console.log("Remove Background : ", activeObject.filters);
          activeObject.applyFilters();
          activeObject.set({
            bgRemove: true,
          });
        } else {
          const filters = activeObject.filters;
          if (filters && filters.length > 0) {
            for (let i = filters.length - 1; i >= 0; i--) {
              if (filters[i] instanceof fabric.Image.filters.RemoveColor) {
                filters.splice(i, 1); // Remove the color filter
              }
            }
          }
          activeObject.applyFilters();
          activeObject.set({
            bgRemove: false,
          });
        }
        canvas.renderAll();
      }
    } catch (error) {
      console.error("Error in removeBgColor:", error);
    }
  };

  const handleSwitchChange = async (checked) => {
    setRemoveBackground(checked);
    const canvas = window._canvas;
    const activeObject = canvas.getActiveObject();
    if (activeObject && activeObject.type === "image") {
      await removeBgColor(checked);
      activeObject.set("bgRemove", checked);
      activeObject.setCoords();
      canvas.renderAll();
    }
  };
  
const applySingleColor = async (checked) => {
  setSingleColorMode(checked); // Update state
  const canvas = window._canvas;
  const activeObject = canvas.getActiveObject();

  if (activeObject && activeObject.type === "image") {
    activeObject.set({
      singleColorMode: checked,  // Set singleColorMode directly on the object
    });

    // Remove any existing blend or grayscale filters
    const existingBlendColorFilter = activeObject.filters.find(
      (filter) => filter instanceof fabric.Image.filters.BlendColor
    );
    const existingGrayscaleFilter = activeObject.filters.find(
      (filter) => filter instanceof fabric.Image.filters.Grayscale
    );

    if (existingBlendColorFilter) {
      activeObject.filters.splice(
        activeObject.filters.indexOf(existingBlendColorFilter),
        1
      );
    }
    if (existingGrayscaleFilter) {
      activeObject.filters.splice(
        activeObject.filters.indexOf(existingGrayscaleFilter),
        1
      );
    }

    // Apply the single color mode filter
    if (checked) {
      setColor("#000000");
      setSingleColor({ r: 0, g: 0, b: 0 });
      const blendColorFilter = new fabric.Image.filters.BlendColor({
        color: "#000000",
        mode: "add",
        alpha: 1,
      });
      activeObject.filters.push(new fabric.Image.filters.Grayscale());
      activeObject.filters.push(blendColorFilter);
    }

    activeObject.set("singleColor", {r:0, g:0, b:0, a:1});
    activeObject.set("singleColorMode", checked);
    activeObject.applyFilters();
    canvas.renderAll();
    calculatePrice(canvas);

    // Save canvas data to local storage
    saveCanvasDataToLocalStorage(activeTab);

  }
};


  
  
  
  const applyMakeColor = async (checked) => {
    setMakeColorMode(checked);
    const canvas = window._canvas;
    const activeObject = canvas.getActiveObject();
  
    if (activeObject && activeObject.type === "image") {
      const existingFilter = activeObject.filters.find(
        (filter) => filter instanceof fabric.Image.filters.BlendColor
      );
      if (existingFilter) {
        activeObject.filters.splice(
          activeObject.filters.indexOf(existingFilter),
          0
        );
      }
      if (checked) {
        setColor("#000000");
        setMakeColor({ r: 0, g: 0, b: 0 });
        activeObject.filters.push(
          new fabric.Image.filters.BlendColor({
            color: "rgb(0,0,0)",
            mode: "tint",
            alpha: 1,
          })
        );
      } else {
        activeObject.filters = activeObject.filters.filter(
          (filter) => !(filter instanceof fabric.Image.filters.BlendColor)
        );

      }
  
      
      activeObject.set("makeColorMode", checked);

  
      if (removeBackground) {
        await removeBgColor(true);
      } else {
        activeObject.set("makeColorMode", checked);
        activeObject.set("makeColor",  {r:0, g:0, b:0, a:1});
        activeObject.applyFilters();
        canvas.renderAll();
              calculatePrice(canvas);
      

        saveCanvasDataToLocalStorage(activeTab); // Save canvas data after single color changes



      }
    }
  };

  const rgbToHex = (color) => {
    const toHex = (value) => {
      const hex = value.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };
    return `#${toHex(color.r)}${toHex(color.g)}${toHex(color.b)}`;
  };
  
  
  const handleChange = async (color) => {
    const hexColor = rgbToHex(color);
    setColor(hexColor);
    setSingleColor(color.rgb);
    const canvas = window._canvas;
    const activeObject = canvas.getActiveObject();
  
    if (singleColorMode) {
      const existingBlendColorFilter = activeObject.filters.find(
        (filter) => filter instanceof fabric.Image.filters.BlendColor
      );
      const existingGrayscaleFilter = activeObject.filters.find(
        (filter) => filter instanceof fabric.Image.filters.Grayscale
      );

      if (existingBlendColorFilter) {
        activeObject.filters.splice(
          activeObject.filters.indexOf(existingBlendColorFilter),
          1
        );
      }
      if (existingGrayscaleFilter) {
        activeObject.filters.splice(
          activeObject.filters.indexOf(existingGrayscaleFilter),
          1
        );
      }
  
      if (activeObject && activeObject.type === "image") {
        const blendColorFilter = new fabric.Image.filters.BlendColor({
          color: hexColor,
          mode: "add",
          alpha: 1,
        });
  
        activeObject.filters.push(new fabric.Image.filters.Grayscale());
        activeObject.filters.push(blendColorFilter);
        activeObject.applyFilters();
        activeObject.set("singleColor", color);
  
        if (removeBackground) {
          await removeBgColor(true);
        } else {
          canvas.renderAll();
        }
      }
    }
  };
  
  const colorChange = async (color) => {
    const canvas = window._canvas;
    const activeObject = canvas.getActiveObject();
  
    if (activeObject && activeObject.type === "image") {
      const rgbColor = `rgb(${color.r},${color.g},${color.b})`;
      setColor(color.hex);
      setMakeColor(color.rgb);
  
      if (makeColorMode) {
        const existingFilter = activeObject.filters.find(
          (filter) => filter instanceof fabric.Image.filters.BlendColor
        );
        if (existingFilter) {
          activeObject.filters.splice(
            activeObject.filters.indexOf(existingFilter),
            0
          );
        }
  
        activeObject.filters.push(
          new fabric.Image.filters.BlendColor({
            color: rgbColor,
            mode: "tint",
            alpha: 1,
          })
        );
  
        activeObject.applyFilters();
        activeObject.set("makeColorMode", true);
        activeObject.set("makeColor", color);
        if (removeBackground) {
          await removeBgColor(true);
        } else {
          canvas.renderAll();
        }
      }
    }
  };
  
  
  const apiUrl1 = "https://api.hebesol.com/api/reset-to-original";
  const handleColorReset = async (e) => {
    e.preventDefault();
    try {
      const activeObject = canvas.getActiveObject();

      const data2 = {
        session_id: activeObject.session_id,
      };

      const response = await axios.post(apiUrl1, data2, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const originalImagePath =
        "https://api.hebesol.com/" + response.data.originalImagePath;
      const originalPalette = response.data.paletteColors;

      const isEqualToExcludedColor1 = (color) => {
        return color[0] === 254 && color[1] === 254 && color[2] === 218;
      };

      const filteredPaletteColors1 = originalPalette.filter(
        (color) => !isEqualToExcludedColor1(color)
      );

      const originalPaletteString = JSON.stringify(filteredPaletteColors1);

      const base = await loadImageToBase64(originalImagePath);
      const object = canvas.getActiveObject();

      if (object && object.type === "image") {
        fabric.util.loadImage(base, function (imgs) {
          let img2 = new fabric.Image(imgs, {
            src: originalImagePath,
            top: object.top,
            flipX: object.flipX,
            flipY: object.flipY,
            img: true,
            width: object.width,
            height: object.height,
            width2: object.width2,
            height2: object.height2,
            left: object.left,
            zoomX: object.zoomX,
            zoomY: object.zoomY,
            filters: object.filters,
            paletteColors: filteredPaletteColors1,
            dominant_color: object.dominant_color,
            bgRemove: object.bgRemove,
            makeOneColorCode: object.makeOneColorCode,
            makeSingleColor: object.makeSingleColor,
            makeSingleColorInvert: object.makeSingleColorInvert,
            makeOneColor: object.makeOneColor,
            angle: object.angle,
            session_id: object.session_id,
            scaleX: object.scaleX,
            scaleY: object.scaleY,
            lock: object.lock,
            cropX: object.cropX,
            cropY: object.cropY,
            quantized_image: object.quantized_image,
            imageFile:object.imageFile,
            imageType:object.imageType,
            newImagePath: originalImagePath,
            type: "image",
            singleColor: object.singleColor,
            extractedColorVal: object.extractedColorVal,
            selectedColorIndex: object.selectedColorIndex,
            size: object.size,
            cache: true,
            imageSmoothingEnabled: true,
            imageSmoothingQuality: "high",
            closePropertiesSidebar,

          });

          canvas.remove(object);
          canvas.add(img2);
          canvas.setActiveObject(img2);
          canvas.renderAll();

          img2.on("loaded", function () {
            img2.getElement().toBlob((blob) => {
              const blobUrl = URL.createObjectURL(blob);
              console.log("Blob URL:", blobUrl);
            });
          });
        });

        // Update the palette colors after resetting
        setPaletteColors(filteredPaletteColors1);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const loadImageToBase64 = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const base64 = canvas.toDataURL("image/png");
        resolve(base64);
      };
      img.onerror = function (error) {
        reject(error);
      };
      img.src = url;
    });
  };
console.log(activeObject.imageFile);

const tabs = [
  {

    
    label: (
      <div className="tab-label">
        <img src={colorclip} alt="Edit Text Icon" />
        <br />
       Color
      </div>
    ),
    content: (
      <div>
        <div className="inner">
          <label style={{ color: "black", fontSize: "12px" }}  className="labFont">Color</label>
          {!singleColorMode && !makeColorMode && (
            <ImagePaletteComponent
              initialPaletteColors={activeObject?.paletteColors}
              canvas={canvas}
              setSingleColor={setSingleColor}
              setIsPng={setIsPng}
              dominantColor={dominantColorCode}
              addToHistory={addToHistory}
              saveCanvasDataToLocalStorage={saveCanvasDataToLocalStorage}
              activeTab={activeTab}
              calculatePrice={calculatePrice}
            />
          )}

          {singleColorMode && (
            <>
            <div className="outline-main">
              <div className=""
                style={{
                  width: "25px",
                  height: "25px",
                  backgroundColor: `rgb(${singleColor.r}, ${singleColor.g}, ${singleColor.b})`,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                onClick={() => setShowColorPicker(!showColorPicker)}
              ></div></div>
              {showColorPicker && (
                <div style={{ position: "", zIndex: "2" }}>
                  <div
                    style={{
                      position: "",
                      top: "0px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                    }}
                    onClick={() => setShowColorPicker(false)}
                  />
                  <ImagePaletteBox
                    onColorChange={handleChange}
                    onClose={() => setShowColorPicker(false)}
                    setSingleColor={setSingleColor}
                  />
                </div>
              )}
            </>
          )}

          {/* Make One Color Picker */}
          {makeColorMode && (
            <>
                        <div className="outline-main">

              <div
                style={{
                  width: "25px",
                  height: "25px",
                  backgroundColor: `rgb(${makeColor.r}, ${makeColor.g}, ${makeColor.b})`,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                onClick={() => setColorPicker(!colorPicker)}
              ></div>
              </div>
              {colorPicker && (
                <div style={{ position: "", zIndex: "2" }}>
                  <div
                    style={{
                      position: "",
                      top: "0px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                    }}
                    onClick={() => setColorPicker(false)}
                  />
                  <ImagePaletteBox
                    onColorChange={colorChange}
                    onClose={() => setColorPicker(false)}
                    setSingleColor={setMakeColor}
                  />
                </div>
              )}
            </>
          )}
        </div>
        {!makeColorMode && (
        <div className="inner">
          <label style={{ color: "black", fontSize: "12px" }} className="labFont">Single Color</label>
          <Switch checked={singleColorMode} onChange={applySingleColor} />
        </div>
        )}
        {!singleColorMode && (
        <div className="inner">
          <label style={{ color: "black", fontSize: "12px" }} className="labFont">Make One Color</label>
          <Switch checked={makeColorMode} onChange={applyMakeColor} />
        </div>
        )}
      </div>
    ),
  },
  {
    label: (
      <div className="tab-label">
        <img src={transformclip} alt="Edit Text Icon" />
        <br />
       Transform
      </div>
    ),
    content: (
      <div>
         <div className="icon-row">
                      <button title="Center Object" onClick={handleCenterObject}        className={isCentered ? "selected" : ""}
>
                      <img src={objectcenter} alt="bringtofront" className="top-icon"/>
                      </button>
                      <button title="Bring to Front" onClick={handleBringToFront}  className={isSelected ? "selected" : ""}>
                      <img src={bringtofront} alt="bringtofront" className="top-icon"/>
                  
                      </button>
                      <button title="Flip Horizontal" onClick={handleFlipHorizontal}  className={isFlippedHorizontal ? "selected" : ""}>
                      <img src={mirrorhorizontal} alt="bringtofront" className="top-icon"/>

                
                      </button>
                      <button title="Flip Vertical" onClick={handleFlipVertical}        className={isFlippedVertical ? "selected" : ""}
>
                      <img src={mirrorvertical} alt="bringtofront" className="top-icon"/>

               
                      </button>
                      <button title="Lock/Unlock Object" onClick={handleToggleLock}>
  {isLocked ? (
    <img src={lock} alt="Locked" className="top-icon" />
  ) : (
    <img src={unlock} alt="Unlocked" className="top-icon" />
  )}
</button>

                    </div>
      </div>
    ),
  },
  {
    label: (
      <div className="tab-label">
        <img src={rotationmobtext} alt="Edit Text Icon" />
        <br />
       Rotation
      </div>
    ),
    content: (
      <div className="inner">
        <label style={{ color: "black", fontSize: "12px" }} className="labFont">Rotation</label>
        <input
          type="range" className="range-bar"
          min="0"
          max="360"
          value={rotation}
          onChange={(e) => handleRotationChange(e.target.value)}
        />
        <span>{rotation}°</span>
      </div>
    ),
  },

  activeObject?.imageFile && {
    label: (
      <div className="tab-label">
        <img src={backgroundColor} alt="Edit Text Icon" />
        <br />
        Background
      </div>
    ),
    content: (
      <div className="inner">
        <label style={{ color: "black", fontSize: "12px" }} className="labFont">
          Remove Background
        </label>
        <Switch checked={removeBackground} onChange={handleSwitchChange} />
      </div>
    ),
  },

];

const handleTabChange = (index) => {
  setActiveTabIndex(index);
};


return (
  <><div
  style={{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px",
    backgroundColor: "#f6f6f6",
  }}
>
  <h5
    style={{
      margin: "0 auto",
      color: "#aaaa",
      fontWeight: "400",
      textAlign: "center",
      flexGrow: 1,
    }}
  >
   Edit image 
  </h5>
  <button
    onClick={closePropertiesSidebar}
    style={{
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      fontSize: "18px",
      fontWeight: "bold",
      marginLeft: "auto",
    }}
    aria-label="Close"
  >
<img src={close} alt="Close" className="closeicon"/>
  </button>
</div>
  <div className="detail-text-box">
    {isMobileScreen ? (
      // For mobile screens, display a tabbed layout
      <div className="mobile-tabs-container"> 
        {/* Tab List */}
        {/* <ul className="tab-list">
          {tabs.map((tab, index) => (
            <li
              key={index}
              className={activeTabIndex === index ? "active" : ""}
              onClick={() => handleTabChange(index)}
            >
              {tab.label}
            </li>
          ))}
        </ul> */}

        {/* Active Tab Content */}
        {activeTabIndex !== null ? (
          <div className="tab-content">
            {tabs[activeTabIndex].content}
            {/* Display color box for Single Color Mode */}
            {/* {activeTabIndex === 0 && singleColorMode && (
              <>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: `rgb(${singleColor.r}, ${singleColor.g}, ${singleColor.b})`,
                    border: "1px solid #000",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                  onClick={() => setShowColorPicker(!showColorPicker)}
                ></div>
                {showColorPicker && (
                  <div style={{ position: "absolute", zIndex: "2" }}>
                    <div
                      style={{
                        position: "fixed",
                        top: "0px",
                        right: "0px",
                        bottom: "0px",
                        left: "0px",
                      }}
                      onClick={() => setShowColorPicker(false)}
                    />
                    <ImagePaletteBox
                      onColorChange={handleChange}
                      onClose={() => setShowColorPicker(false)}
                      setSingleColor={setSingleColor}
                    />
                  </div>
                )}
              </>
            )} */}
            {/* Display color box for Make One Color Mode */}
            {/* {activeTabIndex === 0 && makeColorMode && (
              <>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: `rgb(${makeColor.r}, ${makeColor.g}, ${makeColor.b})`,
                    border: "1px solid #000",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                  onClick={() => setColorPicker(!colorPicker)}
                ></div>
                {colorPicker && (
                  <div style={{ position: "absolute", zIndex: "2" }}>
                    <div
                      style={{
                        position: "fixed",
                        top: "0px",
                        right: "0px",
                        bottom: "0px",
                        left: "0px",
                      }}
                      onClick={() => setColorPicker(false)}
                    />
                    <ImagePaletteBox
                      onColorChange={colorChange}
                      onClose={() => setColorPicker(false)}
                      setSingleColor={setMakeColor}
                    />
                  </div>
                )}
              </>
            )} */}
          </div>
        ) : (
          <div className="tab-placeholder"></div>
        )}
         <ul className="tab-list">
          {tabs.map((tab, index) => (
            <li
              key={index}
              className={activeTabIndex === index ? "active" : ""}
              onClick={() => handleTabChange(index)}
            >
              {tab.label}
            </li>
          ))}
        </ul>
      </div>
    ) : (
      // For larger screens, display all content without tabs
      <>
        {/* Transform Controls */}
        <div className="icon-row">
                      <button title="Center Object" onClick={handleCenterObject}        className={isCentered ? "selected" : ""}
>
                      <img src={objectcenter} alt="bringtofront" className="top-icon"/>
                      </button>
                      <button title="Bring to Front" onClick={handleBringToFront}  className={isSelected ? "selected" : ""}>
                      <img src={bringtofront} alt="bringtofront" className="top-icon"/>
                  
                      </button>
                      <button title="Flip Horizontal" onClick={handleFlipHorizontal}  className={isFlippedHorizontal ? "selected" : ""}>
                      <img src={mirrorhorizontal} alt="bringtofront" className="top-icon"/>

                
                      </button>
                      <button title="Flip Vertical" onClick={handleFlipVertical}        className={isFlippedVertical ? "selected" : ""}
>
                      <img src={mirrorvertical} alt="bringtofront" className="top-icon"/>

               
                      </button>
                      <button title="Lock/Unlock Object" onClick={handleToggleLock}>
  {isLocked ? (
    <img src={lock} alt="Locked" className="top-icon" />
  ) : (
    <img src={unlock} alt="Unlocked" className="top-icon" />
  )}
</button>

                    </div>

        {/* Rotation Control */}
        <div className="inner">
          <label style={{ color: "black", fontSize: "12px" }} className="labFont">Rotation</label>
          <input className="range-bar"
            type="range"
            min="0"
            max="360"
            value={rotation}
            onChange={(e) => handleRotationChange(e.target.value)}
          />
          <span>{rotation}°</span>
        </div>

        {/* Colors (when not in Single Color or Make One Color modes) */}
        <div className="inner" style={{ display: "flex", alignItems: "center" }}>
          <label style={{ color: "black", fontSize: "12px" }} className="labFont">Color</label>
          {!singleColorMode && !makeColorMode && (
            <div>
              {activeObject.paletteColors === null ? (
                <span
                  style={{ color: "black", fontSize: "12px", marginLeft: "10px" }}
                >
                  Full Color
                </span>
              ) : (
                <ImagePaletteComponent
                  initialPaletteColors={activeObject.paletteColors}
                  canvas={canvas}
                  setSingleColor={setSingleColor}
                  setIsPng={setIsPng}
                  dominantColor={dominantColorCode}
                  addToHistory={addToHistory}
                  saveCanvasDataToLocalStorage={saveCanvasDataToLocalStorage}
                  activeTab={activeTab}
                  setShowImagePalette={setShowImagePalette}
                  calculatePrice={calculatePrice}
                />
              )}
            </div>
          )}

          {/* Single Color Picker */}
          {singleColorMode && (
            <>
                        <div className="outline-main">

              <div
                style={{
                  width: "25px",
                  height: "25px",
                  backgroundColor: `rgb(${singleColor.r}, ${singleColor.g}, ${singleColor.b})`,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                onClick={() => setShowColorPicker(!showColorPicker)}
              ></div>
              </div>
              {showColorPicker && (
                <div style={{ position: "", zIndex: "2" }}>
                  <div
                    style={{
                      position: "",
                      top: "0px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                    }}
                    onClick={() => setShowColorPicker(false)}
                  />
                  <ImagePaletteBox
                    onColorChange={handleChange}
                    onClose={() => setShowColorPicker(false)}
                    setSingleColor={setSingleColor}
                  />
                </div>
              )}
            </>
          )}

          {/* Make One Color Picker */}
          {makeColorMode && (
            <>
                        <div className="outline-main">

              <div
                style={{
                  width: "25px",
                  height: "25px",
                  backgroundColor: `rgb(${makeColor.r}, ${makeColor.g}, ${makeColor.b})`,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
                onClick={() => setColorPicker(!colorPicker)}
              ></div>
              </div>
              {colorPicker && (
                <div style={{ position: "", zIndex: "2" }}>
                  <div
                    style={{
                      position: "",
                      top: "0px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                    }}
                    onClick={() => setColorPicker(false)}
                  />
                  <ImagePaletteBox
                    onColorChange={colorChange}
                    onClose={() => setColorPicker(false)}
                    setSingleColor={setMakeColor}
                  />
                </div>
              )}
            </>
          )}
        </div>

        {/* Single Color Switch */}
        {!makeColorMode && (
          <div className="inner">
            <label style={{ color: "black", fontSize: "12px" }} className="labFont">Single Color</label>
            <Switch checked={singleColorMode} onChange={applySingleColor} />
          </div>
        )}

        {/* Make One Color Switch */}
        {!singleColorMode && (
          <div className="inner">
            <label style={{ color: "black", fontSize: "12px" }} className="labFont">Make One Color</label>
            <Switch checked={makeColorMode} onChange={applyMakeColor} />
          </div>
        )}

        {/* Remove Background Switch */}
        {activeObject?.imageFile && (
          <div className="inner">
            <label style={{ color: "black", fontSize: "12px" }} className="labFont">Remove Background</label>
            <Switch checked={removeBackground} onChange={handleSwitchChange} />
          </div>
        )}

        {/* Auto Resize Display */}
      
        <div className="inner">
      <label style={{ color: "black", fontSize: "12px" }} className="labFont">Auto Resize</label>
      <span style={{ color: "black", fontSize: "10px" }}className="labFont"><span className="inches">{imgwidth}</span>in</span>{" "}
      <span style={{ color: "black", fontSize: "10px" }}className="labFont">x</span>{" "}
      <span style={{ color: "black", fontSize: "10px" }}className="labFont"><span className="inches">{imgheight}</span>in</span>
    </div>
      </>
    )}
  </div>
  </>
);




}

