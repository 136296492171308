import { Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import Container from "../../components/container/Container";
import Description from "../../components/typography/description/Description";
import Title from "../../components/typography/title/Title";
import SocialIcons from "../../components/socialIcons/SocialIcons";
import PaymentIcons from "../../components/paymentIcons/PaymentIcons";

const Footer = () => {
  const menues = [
    {
      title: "Account",
      data: [
        { link: "/", label: "My Account" },
        { link: "/", label: "Order History" },
        { link: "/", label: "Wishlist" },
        { link: "/", label: "Specials" },
      ],
    },
    {
      title: "Services",
      data: [
        { link: "/", label: "Discount Returns" },
        { link: "/", label: "Policy" },
        { link: "/", label: "Customer Service" },
        { link: "/", label: "Term & condition" },
      ],
    },
    {
      title: "Info",
      data: [
        { link: "/", label: "About us" },
        { link: "/", label: "FAQ" },
        { link: "/", label: "Delivery Information" },
        { link: "/", label: "Contact us" },
      ],
    },
  ];

  const socialLinks = [
    "https://www.facebook.com/",
    "https://www.instagram.com/",
    "https://twitter.com/?lang=en",
    "https://pk.linkedin.com/",
    "https://www.youtube.com/",
  ];

  return (
    <footer className="footer-main">
      <Container>
        <Row gutter={[40, 0]}>
          <Col md={12}>
            <Row gutter={[40, 0]}>
              <Col xs={24} md={12}>
                <Link to={"/"} className="footer-logo">
                  <img src={logo} alt="logo" />
                </Link>
       
                <SocialIcons data={socialLinks} />
              </Col>
              <Col xs={24} md={10}>
                <Title
                  customClass={"contact-title"}
                  tag={"h3"}
                  size={"25"}
                  weight={700}
                >
                  Contact Us
                </Title>
                <Description className="footer-contact-text">
                Have questions or need assistance? We're here to help! Reach out to us and we’ll get back to you as soon as possible.
                </Description>
                <Title
                  customClass={"contact-no"}
                  tag={"h3"}
                  size={"25"}
                  weight={700}
                >
                  {`<a href={"tel:+01 0000 1111 1111"}>+01 0000 1111 1111</a>`}
                </Title>
              </Col>
            </Row>
          </Col>
          <Col md={12}>
            <Row justify={"space-between"} gutter={[40, 0]}>
              {menues?.map((item1, index) => {
                return (
                  <Col xs={12} md={7} key={index}>
                    <Title tag={"h3"} size={"25"} weight={700}>
                      {item1.title}
                    </Title>
                    <ul className="footer-links">
                      {item1.data?.map((item2, i) => {
                        return (
                          <li key={i}>
                            <Link to={item2.link}>{item2.label}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="copyright">
        <Container>
          <Row gutter={[20, 20]} align={"middle"}>
            <Col md={12}>
              <Title tag={"h3"} size={"27"} weight={500}>
                Copyright @2022 all right recovered
              </Title>
            </Col>
            <Col md={12}>
              <PaymentIcons />
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
