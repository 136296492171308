import React, { useEffect, useState } from 'react';
import './checkOut.css';
import LayoutWrapper from '../layout/layoutWrapper/LayoutWrapper';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { GetColorName } from 'hex-color-to-color-name';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_test_51Q2XZHASGTzYRaXbOk8OerF17IowjpqLF9WwxpFQ7ukeMIL7kU39b6IT01YIoNYc2Q2A6Z2iX59jIq6Ork5BNfLn000nY4X6Q7');

function CheckOut() {
    const [form, setForm] = useState({
        nameoforganization: '',
        emailAddress: '',
        firstName: '',
        lastName: '',
        phone: '',
        subscribe: true,
    });

    const [deliveryForm, setDeliveryForm] = useState({
        deliveryFirstName: '',
        deliveryLastName: '',
        organization: '',
        country: 'United Kingdom',
        address: '',
        city: '',
        state: '',
        zip: '',
    });

    const [cartItems, setCartItems] = useState([]);  // To store fetched cart data
    const [totalCartValue, setTotalCartValue] = useState(0);  // For total cart value
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [deliverySubmitted, setDeliverySubmitted] = useState(false);
    const [showDeliveryFields, setShowDeliveryFields] = useState(false);
    const [showPaymentFields, setShowPaymentFields] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const navigate = useNavigate();
    const [paymentMethod, setPaymentMethod] = useState('creditCard');
    const email = localStorage.getItem('Email');
    const email1 = Cookies.get('Email_guest');
const [clientSecret, setClientSecret] = useState('');
    // Fetch the cart data using email from cookies
    const fetchCartData = (emailToFetch) => {
        fetch(`https://backend.inkox.com/api/checkouts/email/${emailToFetch}`)
            .then(response => response.json())
            .then(data => {
                setCartItems(data);  // Set the cart data
                // Calculate the total cart value
                const totalValue = data.reduce((total, item) => total + parseFloat(item.total_price_with_delivery), 0);
                setTotalCartValue(totalValue.toFixed(2));
            })
            .catch(error => console.error('Error fetching cart data:', error));
    };

    useEffect(() => {
        if (email) {
            fetchCartData(email);
        } else if (email1) {
            fetchCartData(email1);
        }
    }, [email, email1]);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhone = (phone) => {
        const phoneRegex = /^\d{11}$/; // Example: simple regex for 11-digit phone number
        return phoneRegex.test(phone);
    };

    const handleInputChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleDeliveryChange = (e) => {
        setDeliveryForm({ ...deliveryForm, [e.target.name]: e.target.value });
    };
   
    useEffect(() => {
        if (email) {
            fetchCartData(email);
        } else if (email1) {
            fetchCartData(email1);
        }

        // Fetch client secret for PaymentElement
        fetch('https://backend.inkox.com/api/create-payment-intent', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ amount:  Math.round(parseFloat(totalCartValue)) }), // Amount in cents
        })
            .then(response => response.json())
            .then(data => {
                setClientSecret(data.clientSecret);
            })
            .catch(error => console.error('Error fetching client secret:', error));
    }, [email, email1, totalCartValue]);

    const handlePlaceOrder = async (paymentDetails) => {
        const placeOrderData = {
            ...form,
            ...deliveryForm,
            cartData: cartItems,
            paymentDetails,
        };

        try {
            const response = await fetch('https://backend.inkox.com/api/place-order', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(placeOrderData),
            });

            if (response.ok) {
                // Delete cart after successful order
                const deleteEndpoint = `https://backend.inkox.com/api/checkouts/email/${email || email1}`;
                await fetch(deleteEndpoint, { method: 'DELETE' });
                navigate("/");
            } else {
                console.error('Failed to place order');
            }
        } catch (error) {
            console.error('Error during checkout:', error);
        }
    };

    const CheckoutForm = ({ totalCartValue, handlePlaceOrder, paymentMethod }) => {
        const stripe = useStripe();
        const elements = useElements();
        const [loading, setLoading] = useState(false);
        const [errorMessage, setErrorMessage] = useState(null);

        const handlePaymentSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            setErrorMessage(null);

            if (!stripe || !elements) return; // Stripe.js hasn't loaded yet

            const { error, paymentIntent } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: 'http://localhost:3000/order-confirmation',
                },
                redirect: 'if_required',
            });
    console.log(paymentIntent.id);
    console.log(paymentIntent.currency);
    console.log(paymentIntent.amount);
            if (error) {
                setErrorMessage(error.message);
                setLoading(false);
            } else {

                const paymentDetails = {
                    paymentId: paymentIntent.id,
                    amount: paymentIntent.amount,
                    currency: paymentIntent.currency,
                    status: paymentIntent.status,
                    created: paymentIntent.created,
                    payment_method: paymentMethod,
                };
                await handlePlaceOrder(paymentDetails);
                setLoading(false);
            }
        };

        return (
            <form onSubmit={handlePaymentSubmit}>
                <PaymentElement />
                {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                <button type="submit" disabled={!stripe || loading} className="submit-btn">
                    {loading ? 'Processing...' : 'Place Your Order'}
                </button>
            </form>
        );
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsFormSubmitted(true); // Flag indicating that the user clicked "Next"
        setShowDeliveryFields(true); // Show delivery information fields
        setIsEditing(false); // Reset editing state
    };

    const handleDeliverySubmit = (e) => {
        e.preventDefault();
        setDeliverySubmitted(true); // Mark delivery form as submitted
        setShowPaymentFields(true); // Show payment form
    };

    const handleEditbtn = () => {
        navigate("/cart");
    };

    const handleEditClick = () => {
        setIsEditing(true); // Allow editing of the main form
        setIsFormSubmitted(false); // Hide preview and revert to form
        setDeliverySubmitted(false); // Reset delivery form submission to allow changes
        setShowPaymentFields(false); // Hide payment fields until re-submission
    };

    const handleEditDeliveryClick = () => {
        setDeliverySubmitted(false); // Allow editing of delivery form
        setShowPaymentFields(false); // Hide payment fields until re-submission
    };



    return (
        <LayoutWrapper>
            <div className="app-container">
                <div className='checkout'>
                    <h2>Checkout</h2>
                    <p>({cartItems.length} items) £{totalCartValue}</p>
                </div>
                <div className="content-wrapper">
                    <div className="main-content">
                        {/* Form Submission or Edit Mode */}
                        {!isFormSubmitted || isEditing ? (
                            <div className="form-section">
                                <form onSubmit={handleSubmit}>
                                    <h2>Contact</h2>

                                    <div className="form-row">
                                        <div className="form-group">
                                            <label>First Name *</label>
                                            <input
                                                type="text"
                                                name="firstName"
                                                value={form.firstName}
                                                onChange={handleInputChange}
                                                required
                                                className={form.firstName ? 'valid-input' : ''}
                                            />
                                            {form.firstName && <span className="success-check">✔</span>}
                                        </div>
                                        <div className="form-group">
                                            <label>Last Name *</label>
                                            <input
                                                type="text"
                                                name="lastName"
                                                value={form.lastName}
                                                onChange={handleInputChange}
                                                required
                                                className={form.lastName ? 'valid-input' : ''}
                                            />
                                            {form.lastName && <span className="success-check">✔</span>}
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group">
                                            <label>Email Address *</label>
                                            <input
                                                type="email"
                                                name="emailAddress"
                                                value={form.emailAddress}
                                                onChange={handleInputChange}
                                                required
                                                className={validateEmail(form.emailAddress) ? 'valid-input' : 'invalid-input'}
                                            />
                                            {form.emailAddress ? (
                                                validateEmail(form.emailAddress) ? (
                                                    <span className="success-check">✔</span>
                                                ) : (
                                                    <span className="error-check">✘</span>
                                                )
                                            ) : null}
                                        </div>
                                        <div className="form-group">
                                            <label>Phone *</label>
                                            <input
                                                type="number"
                                                name="phone"
                                                value={form.phone}
                                                onChange={handleInputChange}
                                                required
                                                className={validatePhone(form.phone) ? 'valid-input' : 'invalid-input'}
                                            />
                                            {form.phone ? (
                                                validatePhone(form.phone) ? (
                                                    <span className="success-check">✔</span>
                                                ) : (
                                                    <span className="error-check">✘</span>
                                                )
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label>Name of Organization *</label>
                                        <input
                                            type="text"
                                            name="nameoforganization"
                                            value={form.nameoforganization}
                                            onChange={handleInputChange}
                                            required
                                            className={form.nameoforganization ? 'valid-input' : ''}
                                        />
                                        {form.nameoforganization && <span className="success-check">✔</span>}
                                    </div>

                                    <button type="submit" className="submit-btn">
                                        Next
                                    </button>
                                </form>
                            </div>
                        ) : (
                            <div className="preview-section">
                                <div className='inner-preview-section'>
                                    <h2>Contact Details</h2>
                                    <div className="preview-details">
                                        <p>
                                            <strong>First Name:</strong> {form.firstName}
                                        </p>
                                        <p>
                                            <strong>Last Name:</strong> {form.lastName}
                                        </p>
                                        <p>
                                            <strong>Email Address:</strong> {form.emailAddress}
                                        </p>
                                        <p>
                                            <strong>Phone Number:</strong> {form.phone}
                                        </p>
                                        <p>
                                            <strong>Name of Organization:</strong> {form.nameoforganization}
                                        </p>
                                    </div>

                                </div>
                                <div className='editbtn-preview'>
                                    <button onClick={handleEditClick} className="edit-btn">
                                        Edit
                                    </button>
                                </div>
                            </div>
                        )}

                        {/* Delivery Information */}
                        {showDeliveryFields && !isEditing && !deliverySubmitted && (
                            <div className="delivery-form">
                                <form onSubmit={handleDeliverySubmit}>

                                    <div className="form-group">
                                        <h2>Delivery Information</h2>

                                        <label>First Name *</label>
                                        <input
                                            type="text"
                                            name="deliveryFirstName"
                                            value={deliveryForm.deliveryFirstName}
                                            onChange={handleDeliveryChange}
                                            required
                                            className={deliveryForm.deliveryFirstName ? 'valid-input' : ''}
                                        />
                                        {deliveryForm.deliveryFirstName && <span className="success-check">✔</span>}
                                    </div>
                                    <div className="form-group">
                                        <label>Last Name *</label>
                                        <input
                                            type="text"
                                            name="deliveryLastName"
                                            value={deliveryForm.deliveryLastName}
                                            onChange={handleDeliveryChange}
                                            required
                                            className={deliveryForm.deliveryLastName ? 'valid-input' : ''}
                                        />
                                        {deliveryForm.deliveryLastName && <span className="success-check">✔</span>}
                                    </div>

                                    <div className="form-group">
                                        <label>Company/Organization (Optional)</label>
                                        <input
                                            type="text"
                                            name="organization"
                                            value={deliveryForm.organization}
                                            onChange={handleDeliveryChange}
                                            className={deliveryForm.organization ? 'valid-input' : ''}
                                        />
                                        {deliveryForm.organization && <span className="success-check">✔</span>}
                                    </div>

                                    <div className="form-group">
                                        <label>Country *</label>
                                        <input
                                            type="text"
                                            name="country"
                                            value={deliveryForm.country}
                                            readOnly
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>Address *</label>
                                        <input
                                            type="text"
                                            name="address"
                                            value={deliveryForm.address}
                                            onChange={handleDeliveryChange}
                                            required
                                            className={deliveryForm.address ? 'valid-input' : ''}
                                        />
                                        {deliveryForm.address && <span className="success-check">✔</span>}
                                    </div>

                                    <div className="form-group">
                                        <label>City *</label>
                                        <input
                                            type="text"
                                            name="city"
                                            value={deliveryForm.city}
                                            onChange={handleDeliveryChange}
                                            required
                                            className={deliveryForm.city ? 'valid-input' : ''}
                                        />
                                        {deliveryForm.city && <span className="success-check">✔</span>}
                                    </div>

                                    <div className="form-group">
                                        <label>State *</label>
                                        <input
                                            type="text"
                                            name="state"
                                            value={deliveryForm.state}
                                            onChange={handleDeliveryChange}
                                            required
                                            className={deliveryForm.state ? 'valid-input' : ''}
                                        />
                                        {deliveryForm.state && <span className="success-check">✔</span>}
                                    </div>

                                    <div className="form-group">
                                        <label>Post Code *</label>
                                        <input
                                            type="text"
                                            name="zip"
                                            value={deliveryForm.zip}
                                            onChange={handleDeliveryChange}
                                            required
                                            className={deliveryForm.zip ? 'valid-input' : ''}
                                        />
                                        {deliveryForm.zip && <span className="success-check">✔</span>}
                                    </div>

                                    <button type="submit" className="submit-btn">
                                        Next
                                    </button>
                                </form>
                            </div>
                        )}

                        {/* Preview Delivery Information */}
                        {deliverySubmitted && !isEditing && (
                            <div className="preview-section">
                                <div className='inner-preview-section'>

                                    <h2>Delivery Information</h2>
                                    <div className="preview-details">
                                        <p>
                                            <strong>First Name:</strong> {deliveryForm.deliveryFirstName}
                                        </p>
                                        <p>
                                            <strong>Last Name:</strong> {deliveryForm.deliveryLastName}
                                        </p>
                                        <p>
                                            <strong>Address:</strong> {deliveryForm.address}
                                        </p>
                                        <p>
                                            <strong>City:</strong> {deliveryForm.city}
                                        </p>
                                        <p>
                                            <strong>State:</strong> {deliveryForm.state}
                                        </p>
                                        <p>
                                            <strong>Post Code:</strong> {deliveryForm.zip}
                                        </p>
                                    </div>
                                </div>
                                <div className='editbtn-preview'>
                                    <button onClick={handleEditDeliveryClick} className="edit-btn">
                                        Edit
                                    </button>
                                </div>
                            </div>
                        )}

                        {/* Payment Options */}
                        {showPaymentFields && !isEditing && deliverySubmitted && (

                            <div className="payment-form">
                                <h2>Payment Options</h2>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            name="paymentMethod"
                                            value="creditCard"
                                            checked={paymentMethod === 'creditCard'}
                                            onChange={(e) => setPaymentMethod(e.target.value)}
                                        />
                                        Credit Card
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="paymentMethod"
                                            value="paypal"
                                            checked={paymentMethod === 'paypal'}
                                            onChange={(e) => setPaymentMethod(e.target.value)}
                                        />
                                        PayPal
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="paymentMethod"
                                            value="gpay"
                                            checked={paymentMethod === 'gpay'}
                                            onChange={(e) => setPaymentMethod(e.target.value)}
                                        />
                                        G Pay
                                    </label>
                                </div>

                                {paymentMethod === 'creditCard' && (
                                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                                        <CheckoutForm totalCartValue={totalCartValue} handlePlaceOrder={handlePlaceOrder} paymentMethod={paymentMethod}/>
                                    </Elements>
                                )}

                                {paymentMethod === 'paypal' && <p>PayPal integration coming soon!</p>}
                                {paymentMethod === 'gpay' && <p>G Pay integration coming soon!</p>}
                            </div>

                        )}
                    </div>

                    {/* Sidebar with Order Summary */}
                    <div className="sidebarcheckout">
                        <div className="order-summary">
                            <div style={{display: "flex", justifyContent: "space-between",}}>
                                <h3>Your Order</h3>
                                <Link to={`/cart`}><h3>Edit</h3></Link>
                            </div>
                            <p>{cartItems.length} items</p>
                            <p>Delivery: <strong>Free</strong></p>
                            <hr />
                            <div className="order-total">
                                <p>Total <strong>£{totalCartValue}</strong></p>
                                <p>(Inclusive of tax £13.33)</p>
                            </div>
                            <p>Make 3 payments of £{(totalCartValue / 3).toFixed(2)} with <strong>Klarna</strong>.</p>
                            <p>18+, T&C apply, Credit subject to status.</p>
                            <hr />

                            <>
                                {cartItems.length > 0 && (
                                    cartItems.map((item, index) => (

                                        <div key={index} className="product-summary">
                                            <div className='shirt-checkout'>
                                                <img
                                                    src={
                                                        item.imagePreviews && item.imagePreviews.find(preview => preview.part === "Front")
                                                            ? item.imagePreviews.find(preview => preview.part === "Front").preview
                                                            : "https://via.placeholder.com/150"
                                                    }
                                                    alt="Product Preview"
                                                    className="product-imagecheckout"
                                                />
                                                <img
                                                    src={
                                                        item.imagePreviews && item.imagePreviews.find(preview => preview.part === "Back")
                                                            ? item.imagePreviews.find(preview => preview.part === "Back").preview
                                                            : "https://via.placeholder.com/150"
                                                    }
                                                    alt="Product Preview"
                                                    className="product-imagecheckout"
                                                />
                                            </div>
                                            <div className="product-info">
                                                <p><strong>{item.productName}</strong></p>
                                                <p>£{item.unitPrice}</p>
                                                <p>Size: {Object.keys(item.quantities).join(', ')} / Quantity: {item.totalQuantity}</p>
                                                <p>Colour: {GetColorName(item.productColor)}</p>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </>

                        </div>
                    </div>
                </div>
            </div>
        </LayoutWrapper>
    );
}

export default CheckOut;
