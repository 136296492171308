import React from 'react';

const svgAddText = `<svg width="28"  class="text-iconj" height="26" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
<path class="st0" d="M19.9507 29.4259H23.1969L24.8201 24.7594H32.2256L33.8488 29.4259H36.9936L30.0953 11.1657H26.849L19.9507 29.4259ZM25.7331 22.1218L28.3707 14.5134H28.5736L31.2112 22.1218H25.7331ZM16.2986 36.5271C15.1827 36.5271 14.2278 36.1301 13.4338 35.3362C12.6398 34.5422 12.2421 33.5866 12.2408 32.4693V8.12229C12.2408 7.00638 12.6385 6.05144 13.4338 5.25746C14.2291 4.46347 15.1841 4.06581 16.2986 4.06445H40.6456C41.7615 4.06445 42.7172 4.46212 43.5125 5.25746C44.3078 6.05279 44.7048 7.00774 44.7035 8.12229V32.4693C44.7035 33.5852 44.3065 34.5408 43.5125 35.3362C42.7185 36.1315 41.7629 36.5285 40.6456 36.5271H16.2986ZM8.18296 44.6428C7.06705 44.6428 6.11211 44.2458 5.31813 43.4518C4.52414 42.6579 4.12647 41.7022 4.12512 40.585V12.1801H8.18296V40.585H36.5878V44.6428H8.18296Z" fill="#ADB8CC"/>
</svg>

`;

const svgClipArt = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path  class="st0" d="M2.83333 21.5C2.19167 21.5 1.64256 21.2717 1.186 20.8152C0.729444 20.3586 0.500778 19.8091 0.5 19.1667V2.83333C0.5 2.19167 0.728667 1.64256 1.186 1.186C1.64333 0.729444 2.19244 0.500778 2.83333 0.5H19.1667C19.8083 0.5 20.3578 0.728667 20.8152 1.186C21.2725 1.64333 21.5008 2.19244 21.5 2.83333V19.1667C21.5 19.8083 21.2717 20.3578 20.8152 20.8152C20.3586 21.2725 19.8091 21.5008 19.1667 21.5H2.83333ZM4 16.8333H18L13.625 11L10.125 15.6667L7.5 12.1667L4 16.8333Z" fill="#4D5E80"/>
</svg>
`;

const svgUploadImage = `<svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path  class="st0" d="M22.575 7.71366C22.1831 5.72766 21.1139 3.93933 19.5499 2.65411C17.9859 1.3689 16.0243 0.666535 14 0.666992C10.6283 0.666992 7.7 2.58033 6.24167 5.38033C4.52694 5.56563 2.94117 6.37807 1.78907 7.66153C0.636966 8.945 -0.000200319 10.6089 4.72415e-08 12.3337C4.72415e-08 16.1953 3.13833 19.3337 7 19.3337H22.1667C25.3867 19.3337 28 16.7203 28 13.5003C28 10.4203 25.6083 7.92366 22.575 7.71366ZM16.3333 11.167V15.8337H11.6667V11.167H8.16667L14 5.33366L19.8333 11.167H16.3333Z" fill="#4D5E80"/>
</svg>
`;

const svgProductColor = `<svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path class="st0" d="M15.0312 5.22241C13.6802 3.66211 12.1597 2.2571 10.4977 1.03335C10.3505 0.930285 10.1753 0.875 9.99562 0.875C9.81599 0.875 9.64072 0.930285 9.49359 1.03335C7.83466 2.25761 6.31704 3.6626 4.96875 5.22241C1.96203 8.67538 0.375 12.3154 0.375 15.7498C0.375 18.3025 1.38906 20.7506 3.1941 22.5557C4.99914 24.3607 7.44729 25.3748 10 25.3748C12.5527 25.3748 15.0009 24.3607 16.8059 22.5557C18.6109 20.7506 19.625 18.3025 19.625 15.7498C19.625 12.3154 18.038 8.67538 15.0312 5.22241ZM16.1086 16.7713C15.8817 18.0387 15.272 19.206 14.3615 20.1163C13.451 21.0266 12.2835 21.6361 11.0161 21.8627C10.9694 21.8701 10.9223 21.8741 10.875 21.8748C10.6555 21.8747 10.4441 21.7922 10.2826 21.6435C10.1211 21.4948 10.0214 21.2909 10.0032 21.0722C9.98506 20.8535 10.0498 20.6359 10.1845 20.4626C10.3192 20.2894 10.5142 20.1731 10.7306 20.1368C12.543 19.8316 14.0808 18.2938 14.3881 16.4782C14.427 16.2493 14.5552 16.0453 14.7445 15.9109C14.9338 15.7766 15.1688 15.7229 15.3977 15.7618C15.6265 15.8007 15.8306 15.9289 15.9649 16.1182C16.0993 16.3075 16.1529 16.5424 16.1141 16.7713H16.1086Z" fill="#4D5E80"/>
</svg>
`;

const ConstantSidebar = ({ handleSidebarChange, selectedObjectType }) => {
  return (
    <div className="constant-sidebar">
      <div 
        className={`option texticon ${(selectedObjectType === 'text' || selectedObjectType === 'path') ? 'selected' : ''}`} 
        onClick={() => handleSidebarChange('text')}
      >
        <div dangerouslySetInnerHTML={{ __html: svgAddText }} />
        Add Text
      </div>
      <div 
        className={`option ${(selectedObjectType === 'clipart' || selectedObjectType === 'group') ? 'selected' : ''}`} 
        onClick={() => handleSidebarChange('clipart')}
      >
        <div dangerouslySetInnerHTML={{ __html: svgClipArt }} />
        Clip Art
      </div>
      <div 
        className={`option change ${selectedObjectType === 'uploadImage' ? 'selected' : ''}`} 
        onClick={() => handleSidebarChange('uploadImage')}
      >
        <div dangerouslySetInnerHTML={{ __html: svgUploadImage }} />
        Upload Image
      </div>
      <div 
        className={`option changeproduct ${selectedObjectType === 'productColor' ? 'selected' : ''}`} 
        onClick={() => handleSidebarChange('productColor')}
      >
        <div dangerouslySetInnerHTML={{ __html: svgProductColor }} />
        Product Color
      </div>
     
    </div>
  );
};

export default ConstantSidebar;
