import React from 'react';
import brands from '../../assets/images/brands.webp'; // Example image paths

const BrandGrid = () => {
  return (
    <div>
      <style>
        {`
          .two-column-layout {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            gap: 20px;
            padding: 40px;
          }
          .brand-image {
            display: block;
            width: 100%;
            height: auto;
          }
          .text-section {
            text-align: left;
          }
          .text-section h2 {
            font-size: 28px;
            font-weight: 700;
            color: #333;
          }
          .text-section p {
       font-size: 16px;
    color: #555;
    line-height: 1.9;
    margin-top: 10px;
    text-transform: capitalize;
          }
          .shop-button {
            margin-top: 20px;
            padding: 12px 24px;
            background-color: #1cc0d9;
            color: white;
            border: none;
            font-size: 14px;
            font-weight: 600;
            border-radius: 5px;
            cursor: pointer;
          }
          .shop-button:hover {
            background-color: #f5f5f5;
            color:#1cc0d9 ;
          }
          @media (max-width: 900px) {
            .two-column-layout {
              grid-template-columns: 1fr;
              text-align: center;
            }
            .text-section {
              text-align: center;
            }
          }
        `}
      </style>

      <div className="two-column-layout">
        {/* First Column: Image */}
        <div>
          <img src={brands} alt="Brands" className="brand-image" />
        </div>

        {/* Second Column: Text Section */}
        <div className="text-section">
          <h2>Brands You Know</h2>
          <p>
            We offer a wide selection of brand-name apparel that's primed for personalization.
            Choose from popular brands like Nike, Carhartt, Comfort Colors, and Under Armour and 
            create distinctive, custom-made apparel.
          </p>
          <button className="shop-button">Shop Featured Brands</button>
        </div>
      </div>
    </div>
  );
};

export default BrandGrid;
