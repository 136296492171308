// App.js
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from './ScrollToTop';
import Home from "./pages/Home";
import Paymentconfirm from "./pages/paymentconfirm";
import Template from "./pages/Template";
import ProductCategory from "./pages/ProductCategory";
import Products from "./pages/Products";
import ProductSingleCategory from "./pages/ProductSingleCategory";
import SingleProductDetail from "./pages/SingleProductDetail";
import CartDetail from "./pages/CartDetail";
import ProductDelivery from "./pages/ProductDelivery";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import UpdateProfile from "./pages/UpdateProfile";
import View from "./view/Index";
import CheckOut from "./checkout/checkOut";
import "react-phone-input-2/lib/style.css";
import "antd/dist/reset.css";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop /> {/* Ensures the page scrolls to the top on route change */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="products" element={<Products />} />
        <Route path="products/:category/:id" element={<ProductCategory />} />
        <Route
          path="products/:category/:id/:subcategory/:id"
          element={<ProductSingleCategory />}
        />
        <Route
          path="products/:category/:id/:subcategory/:id/:name/:id"
          element={<SingleProductDetail />}
        />
        <Route path="designer" element={<View />} />
        <Route path="cart" element={<CartDetail />} />
        <Route path="checkout" element={<CheckOut />} />
        <Route path="product-delivery" element={<ProductDelivery />} />
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="update-profile" element={<UpdateProfile />} />
        {/* <Route path="/" element={<Template />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
