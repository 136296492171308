import { MenuFoldOutlined } from "@ant-design/icons";
import { Col, Drawer, Menu, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import Cart from "../../assets/images/cart.png";
import login from "../../assets/images/login.png";
import envelope from '../../view/assets/Vector (3).png';


import bumberger from "../../assets/humbergertool.png";

import Container from "../../components/container/Container";
import { selectUser } from "../../redux_apis/redux_login/slice";
import { removeFromLocalStorage } from "../../utils/helperFunctions";
import ChatTalk from "../../widgets/chatTalk/ChatTalk";
import './headerTool.css';
import PriceSidebar from '../../view/components/PriceSidebar';



const HeaderTool = ({ handleGetPriceClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState("/");
  const user = useSelector(selectUser);

  const items = [
    {
      label: "products",
      key: "products",
    },

    {
      label: "Template",
      key: "template",
      children: [
        {
          label: "School Template",
          key: "setting:1",
        },
        {
          label: "Birthday Template",
          key: "setting:2",
        },
        {
          label: "Events Template",
          key: "setting:3",
        },
        {
          label: "Adobe Template",
          key: "setting:4",
        },
      ],
    },
    {
      label: "Help Center",
      key: "help-center",
    },
    {
      label: user?.name ? user?.name : "Login",
      key: user?.name ? user?.name : "login",
      children: [
        {
          label: "Update Profile",
          key: "update-profile",
        },
        {
          label: "Update Password",
          key: "update-password",
        },
        {
          label: "Logout",
          key: "logout",
        },
      ],
    },

    {
      label: "DESIGN NOW",
      key: "designer?id=24&pid=73&colorId=700007",
      className: "design-now-btn", // Added className for custom styling
      url:'http://localhost:3000/designer?id=24&pid=73&colorId=700007',

    }
  ];
  
  useEffect(() => {
    if (location?.pathname) {
      let route = location?.pathname?.split("/")?.[1];
      setCurrent(route);
    }
  }, [location?.pathname]);

  const onClick = (e) => {
    if (e.key === "logout") {
      removeFromLocalStorage("APP_TOKEN");
      window.location.reload();
    } else {
      if (e.key && e.key !== current) {
        setCurrent(e.key);
        navigate(`/${e.key}`);
      }
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleSidebar = () => {
    setOpen(true);
  };

  const handleOpen = () => {
    handleGetPriceClick();
  }
  return (
    <header className="header">
      <div className="main-header-tool">
        <Container>
<Row gutter={[40, 20]} justify="space-between" align="middle" className="tool-header-cls">
  {/* Left side: Burger menu and logo */}
  <Col xs={16} md={16} className="tool-headercls main-logomenu" style={{ display: 'flex', alignItems: 'center' }}>
    <img 
      src={bumberger} 
      alt="Menu" 
      className="sideBarIcon" 
      onClick={handleSidebar} 
      style={{ marginRight: "10px", cursor: 'pointer', width: "24px", height: "auto" }} // Adjust size as needed
    />
    <Link to={"/"} className="brand-logo tool-logo-brand">
      <img src={logo} alt="logo" />
    </Link>
  </Col>

  {/* Right side: ChatTalk - Hidden on mobile */}
  <Col xs={0} md={0} lg={8} style={{ textAlign: "right" }} className="tool-headercls chatTalkcls">
    <ChatTalk />
  </Col>
  <Col xs={8} md={8} lg={0} style={{ textAlign: "right" }} className="tool-headercls chatTalkcls">
   <button onClick={handleOpen} className="get-price-button get-price-buttonheader ">
   <span>
                  {/* <img src={envelope} /> */}
                </span>
                <span>Get Price</span>
   </button>
  </Col>
</Row>




        </Container>
      </div>

      <Drawer
        placement="left"
        onClose={onClose}
        open={open}
        className="header-sidebar"
      >
        <Menu
          onClick={onClick}
          selectedKeys={[current]}
          mode="inline"
          items={items}
        />
      </Drawer>

    </header>

  );
};

export default HeaderTool;
